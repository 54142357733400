<div class="page">
    <app-header></app-header>
    <div class="headerHeight"></div>

    <!-- part 1 -->
    <div class="content">
        <div style="height: 1px;"></div>

        <div class="container xs-10">
            <div class="lgpd-section-title">
                <h2 class="">Contrato de licença de uso e prestação de serviços do QualityManager ® - Rev. 5.0</h2>
            </div>
            <div class="row d-flex flex-center">

                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div>
                        <!--  -->

                        <p class="text-justify">Estes Termos e Condi&ccedil;&otilde;es de Uso (&quot;Termos&quot;) regulam o acesso e a utiliza&ccedil;&atilde;o da plataforma e de seus servi&ccedil;os, oferecidos pela Cyber1. Ao acessar ou utilizar a plataforma, voc&ecirc; (&quot;Licenciada&quot;) declara que leu, compreendeu e concorda em cumprir integralmente os presentes Termos. Caso discorde de quaisquer disposi&ccedil;&otilde;es, interrompa imediatamente o uso da plataforma.</p>
                        <p class="text-justify"></p>
                        <p class="text-justify"><strong>1. LICENCIANTE E LICENCIADA</strong></p>
                        <p class="text-justify">1.1. Licenciante</p>
                        <p class="text-justify">Cyber1 Desenvolvimento e Tecnologia Ltda., sediada &agrave; Rua Nossa Senhora da Lapa, 671 &ndash; Cj 11, S&atilde;o Paulo/SP, CEP: 05072-900, inscrita no CNPJ sob o n&ordm; 50.219.996/0001-43, isenta de inscri&ccedil;&atilde;o estadual, doravante denominada Licenciante.</p>
                        <p class="text-justify">1.2. Licenciada</p>
                        <p class="text-justify">Pessoa Jur&iacute;dica adquirente da licen&ccedil;a de uso do QualityManager&reg;, identificada atrav&eacute;s da aceita&ccedil;&atilde;o destes Termos, doravante denominada Licenciada.</p>
                        <p class="text-justify"></p>
                        <p class="text-justify"><strong>2. DEFINI&Ccedil;&Atilde;O DO SERVI&Ccedil;O</strong></p>
                        <p class="text-justify">2.1. Natureza do Servi&ccedil;o</p>
                        <p class="text-justify">O QualityManager&reg; &eacute; um software disponibilizado no modelo Software como Servi&ccedil;o (SaaS), acessado pela internet mediante assinatura mensal.</p>
                        <p class="text-justify">2.2. Responsabilidades</p>
                        <p class="text-justify">A Licenciante &eacute; respons&aacute;vel pela manuten&ccedil;&atilde;o, infraestrutura e atualiza&ccedil;&otilde;es do sistema.</p>
                        <p class="text-justify">A Licenciada deve utilizar o servi&ccedil;o em conformidade com estes Termos, garantindo que os dados inseridos estejam de acordo com a legisla&ccedil;&atilde;o vigente.</p>
                        <p class="text-justify">2.3. Pagamentos e Reajuste</p>
                        <p class="text-justify"><span size="2" style="font-size:13px;">Os pagamentos referem-se exclusivamente ao uso do servi&ccedil;o no per&iacute;odo contratado e s&atilde;o efetuados mensalmente, conforme estipulado na proposta comercial.&nbsp;</span></p>
                        <p class="text-justify"><span size="2" style="font-size:13px;">Os servi&ccedil;os de implementa&ccedil;&atilde;o, denominados onboarding, s&atilde;o pagos apenas na contrata&ccedil;&atilde;o.&nbsp;</span></p>
                        <p class="text-justify">O reajuste ser&aacute; anual com base no IGP-M (FGV).</p>
                        <p class="text-justify">2.4. Melhorias e Customiza&ccedil;&otilde;es</p>
                        <p class="text-justify">Melhorias gerais fazem parte do servi&ccedil;o e s&atilde;o disponibilizadas regularmente.</p>
                        <p class="text-justify">Solicita&ccedil;&otilde;es espec&iacute;ficas de customiza&ccedil;&atilde;o ou desenvolvimento sob demanda n&atilde;o est&atilde;o inclu&iacute;das e ser&atilde;o cobradas separadamente mediante or&ccedil;amento pr&eacute;vio.</p>
                        <p class="text-justify">2.5. Integra&ccedil;&atilde;o com a Proposta Comercial</p>
                        <p class="text-justify">A proposta comercial aprovada pela Licenciada faz parte destes Termos e &eacute; vinculativa.</p>
                        <p class="text-justify"></p>
                        <p class="text-justify"><strong>3. INFORMA&Ccedil;&Otilde;ES FISCAIS E TRIBUT&Aacute;RIAS</strong></p>
                        <p class="text-justify">3.1. Regime Tribut&aacute;rio</p>
                        <p class="text-justify">A Licenciante &eacute; optante pelo Simples Nacional, regime que pode impactar a emiss&atilde;o de notas fiscais.</p>
                        <p class="text-justify">3.2. Declara&ccedil;&atilde;o da Licenciada</p>
                        <p class="text-justify">A Licenciada declara estar ciente e de acordo com o regime fiscal adotado pela Licenciante.</p>
                        <p class="text-justify"></p>
                        <p class="text-justify"><strong>4. USO DA PLATAFORMA</strong></p>
                        <p class="text-justify">4.1. Finalidade e Responsabilidades</p>
                        <p class="text-justify">O uso do QualityManager&reg; deve respeitar a legisla&ccedil;&atilde;o aplic&aacute;vel, incluindo a LGPD (Lei Geral de Prote&ccedil;&atilde;o de Dados).</p>
                        <p class="text-justify">A Licenciada &eacute; respons&aacute;vel por:</p>
                        <ul>
                            <li>
                                <p class="text-justify">Todo o conte&uacute;do inserido ou gerado na plataforma.</p>
                            </li>
                            <li>
                                <p class="text-justify">Garantir que os dados compartilhados estejam de acordo com a legisla&ccedil;&atilde;o vigente.</p>
                            </li>
                            <li>
                                <p class="text-justify">A Licenciante n&atilde;o se responsabiliza por:</p>
                            </li>
                            <li>
                                <p class="text-justify">Dados incorretos, inconsistentes ou il&iacute;citos inseridos pela Licenciada.</p>
                            </li>
                            <li>
                                <p class="text-justify">Resultados ou impactos decorrentes do uso da plataforma.</p>
                            </li>
                        </ul>
                        <p class="text-justify"></p>
                        <p class="text-justify"><strong>5. LICEN&Ccedil;A DE USO E RESTRI&Ccedil;&Otilde;ES</strong></p>
                        <p class="text-justify">5.1. Licen&ccedil;a de Acesso</p>
                        <p class="text-justify">A Licenciada recebe uma licen&ccedil;a limitada, n&atilde;o exclusiva, intransfer&iacute;vel e revog&aacute;vel para acessar a plataforma.</p>
                        <p class="text-justify">5.2. Proibi&ccedil;&otilde;es</p>
                        <ul>
                            <li>
                                <p class="text-justify">&Eacute; proibido, salvo autoriza&ccedil;&atilde;o expressa por escrito:</p>
                            </li>
                            <li>
                                <p class="text-justify">Modificar, copiar ou criar vers&otilde;es derivadas da plataforma.</p>
                            </li>
                            <li>
                                <p class="text-justify">Realizar engenharia reversa ou tentar acessar o c&oacute;digo-fonte.</p>
                            </li>
                        </ul>
                        <p class="text-justify"></p>
                        <p class="text-justify"><strong>6. BENEF&Iacute;CIOS DA CONTRATA&Ccedil;&Atilde;O</strong></p>
                        <p class="text-justify">6.1. Servi&ccedil;os Inclu&iacute;dos</p>
                        <p class="text-justify">A contrata&ccedil;&atilde;o inclui:</p>
                        <ul>
                            <li>
                                <p class="text-justify">Treinamento ilimitado via EAD (24/7).</p>
                            </li>
                            <li>
                                <p class="text-justify">Atendimento t&eacute;cnico via sistema de tickets.</p>
                            </li>
                            <li>
                                <p class="text-justify">Atualiza&ccedil;&otilde;es normativas conforme a IATF 16949.</p>
                            </li>
                            <li>
                                <p class="text-justify">Backup e seguran&ccedil;a em servidores de alta performance.</p>
                            </li>
                        </ul>
                        <p class="text-justify">6.2. Servi&ccedil;os N&atilde;o Inclu&iacute;dos</p>
                        <p class="text-justify">Treinamentos presenciais e customiza&ccedil;&otilde;es s&atilde;o cobrados separadamente.</p>
                        <p class="text-justify"></p>
                        <p class="text-justify"><strong>7. PROTE&Ccedil;&Atilde;O DE DADOS</strong></p>
                        <p class="text-justify">7.1. Privacidade e Seguran&ccedil;a</p>
                        <p class="text-justify">A Licenciante adota medidas para proteger os dados armazenados na plataforma, conforme a LGPD e demais normas aplic&aacute;veis.</p>
                        <p class="text-justify">A Licenciada &eacute; integralmente respons&aacute;vel pela legalidade dos dados inseridos na plataforma.</p>
                        <p class="text-justify">7.2. Uso dos Dados</p>
                        <p class="text-justify"><span size="2" style="font-size:13px;">Os dados da Licenciada ser&atilde;o utilizados exclusivamente para a presta&ccedil;&atilde;o dos servi&ccedil;os, conforme descrito na Pol&iacute;tica de Privacidade, dispon&iacute;vel em&nbsp;</span><span style="color: rgb(70, 120, 134);"><u><a href="https://www.qualitymanager.com.br/" target="_new">qualitymanager.com.br</a></u></span>.</p>
                        <p class="text-justify"></p>
                        <p class="text-justify"><strong>8. DISPONIBILIDADE E SUPORTE (SLA)</strong></p>
                        <p class="text-justify">8.1. Garantia de Disponibilidade</p>
                        <p class="text-justify">A plataforma tem 99,1% de disponibilidade anual, exceto em casos de:</p>
                        <ul>
                            <li>
                                <p class="text-justify">Manuten&ccedil;&otilde;es programadas (com aviso pr&eacute;vio de 24h).</p>
                            </li>
                            <li>
                                <p class="text-justify">Eventos de for&ccedil;a maior (ex.: desastres naturais, ataques cibern&eacute;ticos).</p>
                            </li>
                        </ul>
                        <p class="text-justify">8.2. Janelas de Manuten&ccedil;&atilde;o</p>
                        <p class="text-justify">A plataforma pode passar por manuten&ccedil;&otilde;es ap&oacute;s as 22h, fins de semana e feriados.</p>
                        <p class="text-justify"></p>
                        <p class="text-justify"><strong>9. LIMITA&Ccedil;&Atilde;O DE RESPONSABILIDADE</strong></p>
                        <p class="text-justify">A plataforma &eacute; fornecida &quot;como est&aacute;&quot; e &quot;conforme dispon&iacute;vel&quot;, sem garantias expressas ou impl&iacute;citas.</p>
                        <p class="text-justify">A Licenciante n&atilde;o se responsabiliza por:</p>
                        <ul>
                            <li>
                                <p class="text-justify">Perda de dados causada por erro da Licenciada.</p>
                            </li>
                            <li>
                                <p class="text-justify">Decis&otilde;es tomadas com base em informa&ccedil;&otilde;es da plataforma.</p>
                            </li>
                            <li>
                                <p class="text-justify">Falhas externas, como problemas de conex&atilde;o &agrave; internet.</p>
                            </li>
                        </ul>
                        <p class="text-justify"></p>
                        <p class="text-justify"><strong>10. RESCIS&Atilde;O E CANCELAMENTO</strong></p>
                        <p class="text-justify">10.1. Rescis&atilde;o por Qualquer das Partes</p>
                        <p class="text-justify">O contrato pode ser rescindido com 30 dias de aviso pr&eacute;vio via e-mail.</p>
                        <p class="text-justify">10.2. Pend&ecirc;ncias de Pagamento</p>
                        <p class="text-justify">A rescis&atilde;o n&atilde;o isenta a Licenciada de d&eacute;bitos pendentes.</p>
                        <p class="text-justify"></p>
                        <p class="text-justify"><strong>11. ALTERA&Ccedil;&Otilde;ES NOS TERMOS E NA PLATAFORMA</strong></p>
                        <p class="text-justify">A Licenciante pode modificar funcionalidades da plataforma e atualizar estes Termos, informando a Licenciada com anteced&ecirc;ncia.</p>
                        <p class="text-justify">O uso cont&iacute;nuo ap&oacute;s as altera&ccedil;&otilde;es constitui aceita&ccedil;&atilde;o dos novos Termos.</p>
                        <p class="text-justify"></p>

                        <p class="text-justify"><strong>12. SERVIÇO OPCIONAL DE ONBOARDING</strong></p>
                        <p class="text-justify">12.1. O Onboarding do QualityManager® é um serviço opcional, oferecido para garantir uma implantação eficiente e personalizada do sistema, com suporte especializado durante os primeiros meses de uso.</p>
                        <p class="text-justify">12.2. Caso o Cliente não contrate o Onboarding, ele não terá direito aos seguintes benefícios exclusivos:</p>
                        <ul>
                            <li>
                                <p class="text-justify">Configuração inicial personalizada, ajustada às necessidades do Cliente;</p>
                            </li>
                            <li>
                                <p class="text-justify">Treinamentos e reuniões exclusivas para capacitação da equipe, limitando-se apenas à documentação padrão fornecida pelo sistema;</p>
                            </li>
                            <li>
                                <p class="text-justify">Suporte prioritário via telefone e WhatsApp durante o período de implantação, tendo acesso somente ao suporte via ticket conforme os termos gerais do contrato;</p>
                            </li>
                            <li>
                                <p class="text-justify">Acompanhamento estratégico por especialistas para auxiliar na adaptação ao sistema e otimização de processos;</p>
                            </li>
                            <li>
                                <p class="text-justify">Apoio técnico na integração do sistema com outros softwares utilizados pelo Cliente;</p>
                            </li>
                            <li>
                                <p class="text-justify">Acesso antecipado a atualizações e boas práticas recomendadas pela equipe de implantação.</p>
                            </li>
                        </ul>
                        <p class="text-justify">12.3. O Cliente que optar por não contratar o Onboarding terá suporte exclusivamente via ticket, seguindo os prazos e procedimentos padrão do QualityManager®, sem atendimento direto via telefone ou WhatsApp.</p>
                        <p class="text-justify">12.4. Caso o Cliente deseje contratar o Onboarding posteriormente, deverá solicitar formalmente à CONTRATADA, estando sujeito à disponibilidade e aos valores vigentes à época da solicitação.</p>

                        <p class="text-justify"><strong>13. DISPOSI&Ccedil;&Otilde;ES GERAIS</strong></p>
                        <p class="text-justify">13.1. Legisla&ccedil;&atilde;o e Foro</p>
                        <p class="text-justify">Estes Termos s&atilde;o regidos pelas leis do Estado de S&atilde;o Paulo, e eventuais disputas ser&atilde;o resolvidas na Comarca de S&atilde;o Paulo/SP.</p>
                        <p class="text-justify">13.2. Uso da Marca</p>
                        <p class="text-justify">A Licenciante pode utilizar a marca e ou logotipo da Licenciada para divulgar em suas m&iacute;dias sociais e propostas cases relativos ao uso do QualityManager&reg;.</p>
                        <p class="text-justify">13.3. Rela&ccedil;&atilde;o com Funcion&aacute;rios e Prestadores</p>
                        <p class="text-justify">A Licenciada n&atilde;o poder&aacute; contratar ex-funcion&aacute;rios ou prestadores da Licenciante dentro de 700 dias ap&oacute;s o desligamento, sob pena de multa de 100 sal&aacute;rios-m&iacute;nimos.</p>
                        <p class="text-justify">13.4. Contato e Atendimento</p>
                        <p class="text-justify"><span size="2" style="font-size:13px;">Nosso e-mail exclusivo para contato &eacute;&nbsp;</span><span style="color: rgb(70, 120, 134);"><u><a href="mailto:contato@qualitymanager.com.br">contato&#64; qualitymanager.com.br</a></u></span> e nosso hor&aacute;rio de atendimento &eacute; de Segunda a sexta, das 8h30 &agrave;s 12h e das 13h &agrave;s 17h (exceto feriados).</p>
                        <br><br>

                        <!-- <p class="text-right">São Paulo, <? echo $formatter->format($date); ?></p> -->
                        <!-- <hr>
                        <div class="row">
                            <div class="col-md-6">
                            </div>
                            <div class="col-md-6">
                                <p class="text-right">
                                    <strong>Histórico de Revisões</strong><strong>Rev. 1.0 </strong> - 11-05-22 -
                                    Alteração nos dados da Licenciante, nomeadamente a razão social e CNPJ da
                                    empresa.
                                    <strong>Rev. 2.0 </strong> - 24-05-22 - Revisão geral dos termos de utilização.
                                    <strong>Rev. 3.0 </strong> - 12-05-23 - Revisão geral dos termos de utilização e
                                    renovação.
                                    <strong>Rev. 4.0 </strong> - 01-07-23 - Revisão geral dos termos de utilização,
                                    fatuamento e reajuste.
                                </p>
                            </div>
                        </div> -->


                        <!--  -->
                    </div>
                </div>
            </div>

        </div>

        <app-footer></app-footer>
    </div>