import { Component } from '@angular/core';

@Component({
  selector: 'app-siga',
  templateUrl: './siga.component.html',
  styleUrl: './siga.component.scss'
})
export class SigaComponent {

  face() {
    let url = 'https://www.facebook.com/quality1app';
    window.open(url);
  }
  linkedin() {
    let url = 'https://www.linkedin.com/company/quality1app';
    window.open(url);
  }
  insta() {
    let url = 'https://www.instagram.com/quality1app/';
    window.open(url);
  }
  twitter() {
    let url = 'https://twitter.com/qualitymanager/';
    window.open(url);
  }

  whats() {
    let url = 'https://wa.me/5511965591234';
    window.open(url);
  }

}
