<div>
    <app-header></app-header>
    <div class="headerHeight"></div>

    <div class="content" [formGroup]="form">

        <!--  -->
        <section class="p1">
            <div class="p1i d-flex w-100">
                <div class="container">
                    <div class="row h-100">
                        <div class="d-none d-lg-flex col-lg-6 h-100" style="align-items: center;">
                            <img src="/assets/images/q1-showcase-570px.webp" style="width: 100%; max-width: 570px;" alt="Imagem mostrando o sistema Quality1">
                        </div>
                        <div class="col-sm-12 col-lg-6 h-100 d-flex" style="justify-content: center; flex-direction: column;">
                            <h1 class="p1Title" style="margin: 0;">{{'ho-title' | translate}}</h1>
                            <h2 style="margin-bottom: 0;" class="p1SubTitle" [innerHTML]="'ho-subtitle' | translate"></h2>
                            <div class="d-flex w-100 justify-content-end" style="margin-top: 80px;">
                                <div class="btnGreen" style="margin-right: 13px;">
                                    <div class="icon"><ion-icon name="lock-closed"></ion-icon></div>
                                    <h3 style="font-size: 20px;">100% {{'ho-safe' | translate}}</h3>
                                </div>
                                <div class="btnGreen" (click)="goSection(5)">
                                    <div class="icon" ><ion-icon name="mail"></ion-icon></div>
                                    <h3>{{'ho-speak' | translate}}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--  -->
        <section class="p2">

            <div style="margin-top: -90px;">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-4">
                            <div class="cardP2">
                                <div class="w-100 d-flex justify-content-center">
                                    <img loading="lazy" src="/assets/images/icon-1-100px.webp" height="100" width="100" alt="Representação que o Quality1 é totalmente Online">
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <h3 class="cardP2Title">{{'ho-on' | translate}}</h3>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <h4 class="cardP2SubTitle">{{'ho-onb' | translate}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-4">
                            <div class="cardP2">
                                <div class="w-100 d-flex justify-content-center">
                                    <!-- <img loading="lazy" src="/assets/images/icon-2-100px.webp" height="100" width="100" alt="Representação que o Quality1 é 100% integrado"> -->
                                    <span class="css-sprite-icon-2-100px"></span>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <h3 class="cardP2Title">100% {{'ho-integrated' | translate}}</h3>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <h4 class="cardP2SubTitle">{{'ho-integrated-txt' | translate}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-4">
                            <div class="cardP2">
                                <div class="w-100 d-flex justify-content-center">
                                    <!-- <img loading="lazy" src="/assets/images/icon-3-100px.webp" height="100" width="100" alt="Representação que o Quality1 é sempre atualizado"> -->
                                    <span class="css-sprite-icon-3-100px"></span>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <h3 class="cardP2Title">{{'ho-always' | translate}}</h3>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <h4 class="cardP2SubTitle">{{'ho-always-txt' | translate}}</h4>
                                </div>
                                 <div #partWhat></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="position: relative;">
                <div id="oque" style="position: absolute; margin-top: -50px;"></div>
            </div>
            <h2 class="p2Title mb-0">{{'qm-title' | translate}}</h2>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <div class="blue-line">
                    <span></span>
                </div>
            </div>
            <span class="p2SubTitle container">{{'qm-subtitle' | translate}}</span>

            <div class="container mt-5">
                <div class="row">
                    <div class="col-12 col-lg-6 d-none d-lg-flex">
                        <!-- <video alt="Q1.App system" autoplay loop>
                            <source src="/assets/images/Login-QM-sm.webm" type="video/webm">
                        </video> -->
                        <img src="/assets/images/Mockup-Q1.png" width="683" height="340" loading="lazy" alt="Mockup do sistema Quality1 em um notebook">
                    </div>
                    <p class="col-12 col-lg-6 p2text" [innerHTML]="'qm-txt' | translate">
                        
                    </p>
                </div>
            </div>

            <div #partDif></div>
        </section>

        <!--  -->
        <div style="position: relative;">
            <div id="diferenciais" style="position: absolute; margin-top: -50px;"></div>
        </div>
        <section class="pGray">
            <div style="height: 1px;"></div>

            <span class="p2Title">{{'dif-title' | translate}}</span>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <div class="blue-line">
                    <span></span>
                </div>
            </div>
            <span class="p2SubTitle container">{{'dif-subtitle' | translate}}</span>
            <br>

            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-4 mt-4">

                        <div class="d-flex mt-5 jE">
                            <div class="difItem1">
                                <span class="difItemTitle tR">{{'dif-1' | translate}}</span>
                                <span class="difItemText tR">{{'dif-1-txt' | translate}}</span>
                            </div>
                            <div class="difItem2">
                                <span class="css-sprite-implantacao-rapida"></span>
                            </div>
                        </div>

                        <div class="d-flex jE">
                            <div class="difItem1">
                                <span class="difItemTitle tR">{{'dif-2' | translate}}</span>
                                <span class="difItemText tR">{{'dif-2-txt' | translate}}</span>
                            </div>
                            <div class="difItem2">
                                <span class="css-sprite-sem-limite-usuarios"></span>
                            </div>
                        </div>


                        <div class="d-flex jE">
                            <div class="difItem1">
                                <span class="difItemTitle tR">{{'dif-3' | translate}}</span>
                                <span class="difItemText tR">{{'dif-3-txt' | translate}}</span>
                            </div>
                            <div class="difItem2">
                                 <span class="css-sprite-suporte-integral"></span>
                            </div>
                        </div>


                    </div>

                    <div class="col-12 col-md-12 col-lg-4" style="display: flex; justify-content: center;">
                        <img loading="lazy" width="350" height="644" src="/assets/images/impressoes.webp" alt="Imagem mostrando papeis que foram imprimidos">
                    </div>

                    <div class="col-12 col-md-12 col-lg-4 mt-4">

                        <div class="d-flex mt-5">
                            <div class="difItem2">
                                <!-- <img loading="lazy" class="difItemImg" width="53" height="60" src="/assets/icon/feature/customizavel.webp" alt="Imagem simbolizando que o Quality1 é customizavel"> -->
                                <span class="css-sprite-customizavel"></span>
                            </div>
                            <div class="difItem1">
                                <span class="difItemTitle tL">{{'dif-4' | translate}}</span>
                                <span class="difItemText tL">{{'dif-4-txt' | translate}}</span>
                            </div>
                        </div>

                        <div class="d-flex">
                            <div class="difItem2">
                                <!-- <img loading="lazy" class="difItemImg" width="53" height="60" src="/assets/icon/feature/escalavel.webp" alt="Imagem simbolizando que o Quality1 é escalavel"> -->
                                <span class="css-sprite-escalavel"></span>
                            </div>
                            <div class="difItem1">
                                <span class="difItemTitle tL">{{'dif-5' | translate}}</span>
                                <span class="difItemText tL">{{'dif-5-txt' | translate}}</span>
                            </div>
                        </div>

                        <div class="d-flex">
                            <div class="difItem2">
                                <!-- <img loading="lazy" class="difItemImg" width="53" height="60" src="/assets/icon/feature/backups.webp" alt="Imagem simbolizando backups"> -->
                                <span class="css-sprite-backups"></span>
                            </div>
                            <div class="difItem1">
                                <span class="difItemTitle tL">{{'dif-6' | translate}}</span>
                                <span class="difItemText tL">{{'dif-6-txt' | translate}}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>

        <!--  -->
        <section class="pBlue">
            <div class="pBluei">
                <div class="container h-100">
                    <div class="row h-100" style="display: flex; align-items: center;">
                        <div class="col-12 col-md-8" style="display: flex; flex-direction: column; justify-content: center;">
                            <h2 class="pBlueTitle" [innerHTML]="'contact-title' | translate"></h2>
                            <h2 class="pBlueSubTitle mt-4 mb-0">{{'contact-subtitle' | translate}}</h2>
                        </div>
                        <div class="col-12 col-md-4"  style="display: flex; flex-direction: column; justify-content: center;">
                            <br class="d-flex d-md-none">
                            <br class="d-flex d-md-none">
                            <button (click)="goSection(5)" class="btnPBlue">{{'contact-btn' | translate}}</button>
                        </div>
                    </div>
                </div>
                <div #partQ></div>
            </div>
        </section>

        <!--  -->
        <div style="position: relative;">
            <div id="porque" style="position: absolute; margin-top: -50px;"></div>
        </div>
        <section class="pWhite">
            <div style="height: 1px;"></div>

            <h2 class="p2Title mb-0">{{'why-title' | translate}}</h2>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <!-- <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="Divisoria de conteudo"> -->
                <div class="blue-line">
                    <span></span>
                </div>
            </div>
            <span class="p2SubTitle container">{{'why-subtitle' | translate}}</span>

            <div class="container mt-5">
                <div class="row">
                    <div class="col-12 col-md-6">

                        <div>
                            <h2 class="whyTitle"><ion-icon name="checkmark"></ion-icon> {{'why-1' | translate}}</h2>
                            <h4 class="whySubTitle">{{'why-1-txt' | translate}}</h4>
                        </div>

                        <div>
                            <h2 class="whyTitle"><ion-icon name="checkmark"></ion-icon> {{'why-2' | translate}}</h2>
                            <h4 class="whySubTitle">{{'why-2-txt' | translate}}</h4>
                        </div>

                    </div>
                    <div class="col-12 col-md-6">

                        <div>
                            <h2 class="whyTitle"><ion-icon name="checkmark"></ion-icon> {{'why-3' | translate}}</h2>
                            <h4 class="whySubTitle">{{'why-3-txt' | translate}}</h4>
                        </div>

                        <div>
                            <h2 class="whyTitle"><ion-icon name="checkmark"></ion-icon> {{'why-4' | translate}}</h2>
                            <h4 class="whySubTitle">{{'why-4-txt' | translate}}</h4>
                        </div>

                    </div>
                </div>
                <div #partPlans></div>
            </div>

        </section>

        <!--  -->
        <!-- <div style="position: relative;">
            <div id="planos" style="position: absolute; margin-top: -50px;"></div>
        </div>
        <section class="pGray">
            <div style="height: 1px;"></div>

            <h2 class="p2Title mb-0">{{'plans-title' | translate}}</h2>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <div class="blue-line">
                    <span></span>
                </div>
            </div>
            <h2 class="p2SubTitle">{{'plans-subtitle' | translate}}</h2>

            <br>
            <div class="container mt-5">
                <div class="row d-flex justify-content-center">
                    <div class="col-12 col-md-6 col-lg-3">
                        <div class="cardPlan">
                            <h3 class="cardPlanTitle poppins mb-0">Business</h3>
                            <br>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span [innerHTML]="'plan-1-more' | translate"> </span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span [innerHTML]="'plan-p1-support' | translate"> </span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span [innerHTML]="'plan-p1-tre' | translate"> </span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span [innerHTML]="'plan-p1-cli' | translate"> </span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span [innerHTML]="'plan-p1-cas' | translate"> </span></div>

                            <button [routerLink]="['/assine']" class="assineBtn">{{'plans-try' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <div class="cardPlan blueCard">
                            <h3 class="cardPlanTitle poppins black mb-0">Premium</h3>
                            <br>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span [innerHTML]="'plan-1-more' | translate"></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span [innerHTML]="'plan-p2-support' | translate"> </span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span [innerHTML]="'plan-p2-tre' | translate"> </span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span [innerHTML]="'plan-p2-cli' | translate"> </span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span [innerHTML]="'plan-p2-cas' | translate"> </span></div>

                            <button [routerLink]="['/assine']" class="assineBtn" style="border: 3px solid #fff;">{{'plans-try' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <div class="cardPlan">
                            <h3 class="cardPlanTitle poppins  mb-0">Enterprise</h3>
                            <br>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span [innerHTML]="'plan-1-more' | translate"></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span [innerHTML]="'plan-p3-support' | translate"> </span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span [innerHTML]="'plan-p3-tre' | translate"> </span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span [innerHTML]="'plan-p3-cli' | translate"> </span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span [innerHTML]="'plan-p3-cas' | translate"> </span></div>

                            <button [routerLink]="['/assine']" class="assineBtn">{{'plans-try' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>

            <h4 [routerLink]="['/compare']" class="compareText poppins mb-3"> {{'plans-compare' | translate}}</h4>
            <span class="trial poppins mb-3">{{'plans-obs' | translate}}</span>

            <br>

        </section> -->

        <!--  -->
        <section class="pBlue">
            <div class="pBluei">
                <div class="container h-100">
                    <div class="row h-100" style="display: flex; align-items: center;">
                        <div class="col-12 col-md-8" style="display: flex; flex-direction: column; justify-content: center;">
                            <h2 class="pBlueTitle" [innerHTML]="'contact-title' | translate"></h2>
                            <h2 class="pBlueSubTitle mt-4 mb-0">{{'contact-subtitle' | translate}}</h2>
                        </div>
                        <div class="col-12 col-md-4"  style="display: flex; flex-direction: column; justify-content: center;">
                            <br class="d-flex d-md-none">
                            <br class="d-flex d-md-none">
                            <button (click)="goSection(5)" class="btnPBlue">{{'contact-btn' | translate}}</button>
                        </div>
                    </div>
                </div>
                <div #partPerg></div>
            </div>
        </section>

        <!--  -->
        <div style="position: relative;">
            <div id="perguntas" style="position: absolute; margin-top: -50px;"></div>
        </div>
        <section class="pGray">
            <div style="height: 1px;"></div>

            <div class="container">

                <h2 class="p2Title mb-0">{{'faq-title' | translate}}</h2>
                <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                    <div class="blue-line">
                        <span></span>
                    </div>
                </div>
                <h2 class="p2SubTitle container mb-0">{{'faq-subtitle' | translate}}</h2>

                <div class="w-100 d-block mt-5">

                    <div class="accor {{accordionOpen == 1 ? 'accorOpen' : ''}}" (click)="openAccor(1)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <h3 class="accorTitle">{{'faq-1' | translate}}</h3>
                        </div>
                        <h4 class="AccorRes poppins">
                            {{'faq-1-txt' | translate}}
                        </h4>
                    </div>

                    <div class="accor {{accordionOpen == 2 ? 'accorOpen' : ''}}" (click)="openAccor(2)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <h3 class="accorTitle">{{'faq-2' | translate}}</h3>
                        </div>
                        <h4 class="AccorRes poppins">
                            {{'faq-2-txt' | translate}}
                        </h4>
                    </div>

                    <div class="accor {{accordionOpen == 3 ? 'accorOpen' : ''}}" (click)="openAccor(3)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <h3 class="accorTitle">{{'faq-3' | translate}}</h3>
                        </div>
                        <h4 class="AccorRes poppins">
                            {{'faq-3-txt' | translate}}
                        </h4>
                    </div>

                    <div class="accor {{accordionOpen == 4 ? 'accorOpen' : ''}}" (click)="openAccor(4)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <h3 class="accorTitle">{{'faq-4' | translate}}</h3>
                        </div>
                        <h4 class="AccorRes poppins">
                            {{'faq-4-txt' | translate}}
                        </h4>
                    </div>

                    <div class="w-100 w-100 d-flex justify-content-end">
                        <span (click)="faq()" class="compareText poppins mb-3">{{'faq-yet' | translate}} </span>
                    </div>

                </div>

            </div>
            <div #partCont></div>


        </section>

        <!--  -->
        <div style="position: relative;">
            <div id="contato" style="position: absolute; margin-top: -50px;"></div>
        </div>
        <section class="pWhite">
            <div style="height: 1px;"></div>

            <h2 class="p2Title mb-0">{{'cont-title' | translate}}</h2>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <div class="blue-line">
                    <span></span>
                </div>
            </div>

            <div class="text-center mt-4">
                <br>

                <div class="w-100 justify-content-center">
                    <div class="container">
                        <div class="row d-flex justify-content-center">
                            <div class="col-12 col-md-6 col-xl-4">
                              <h4 class="poppins d-flex city mb-0"> <ion-icon class="iconLast" name="location-sharp"></ion-icon> São Paulo – Brasil</h4>
                              <h5 class="poppins d-flex normal mb-0">Avenida Brigadeiro Faria Lima, 1811</h5>
                              <h5 class="poppins d-flex normal mb-0">Conjunto 1119</h5>
                              <h5 class="poppins d-flex normal mb-0">Jardim América</h5>
                              <h5 class="poppins d-flex normal mb-0">CEP 01452-001</h5>
                              <br>
              
                              <h4 class="poppins d-flex normal mb-0"> <ion-icon class="iconLast" name="call"></ion-icon> Tel.: (+55) 11 2189 0150</h4>
                              <h4 class="poppins d-flex normal mb-0"> <ion-icon class="iconLast" name="logo-whatsapp"></ion-icon> WhatsApp: (+55) 11 96559 1234</h4>
                              <br><br>
                          </div>
                            <div class="col-12 col-md-6 col-xl-4">
                              <h4 class="poppins d-flex city mb-0"> <ion-icon class="iconLast" name="location-sharp"></ion-icon> Aveiro – Portugal</h4>
                              <h5 class="poppins d-flex normal mb-0">Praça Luis Ribeiro 21</h5>
                              <h5 class="poppins d-flex normal mb-0">2 andar, sala 511</h5>
                              <h5 class="poppins d-flex normal mb-0">São João da Madeira</h5>
                              <h5 class="poppins d-flex normal mb-0">CEP 3700-214</h5>
                              <br>
              
                              <h4 class="poppins d-flex normal mb-0"> <ion-icon class="iconLast" name="call"></ion-icon> Tel.: (+351) 300 305 037</h4>
                              <h4 class="poppins d-flex normal mb-0"> <ion-icon class="iconLast" name="logo-whatsapp"></ion-icon> WhatsApp: (+351) 93883 4456</h4>
                              <br><br>
                            </div>
                          </div>
                    </div>
                        
                </div>

                <br>

                <h4 (click)="sendEmail()" class="poppins d-flex normal blueHover  mb-0" style="color: black;"> <ion-icon class="iconLast" name="send"></ion-icon> help&#64;qualitymanager.com.br</h4>

                <div class="d-flex justify-content-center mt-3 mb-3">
                    <div (click)="face()" class="iconDiv"><ion-icon name="logo-facebook"></ion-icon></div>
                    <div (click)="linkedin()" class="iconDiv"><ion-icon name="logo-linkedin"></ion-icon></div>
                    <div (click)="insta()" class="iconDiv"><ion-icon name="logo-instagram"></ion-icon></div>
                    <div (click)="whats()" class="iconDiv"><ion-icon name="logo-whatsapp"></ion-icon></div>
                    <div (click)="twitter()" class="iconDiv"><ion-icon name="logo-twitter"></ion-icon></div>
                </div>

            </div>

        </section>

    </div>

    <app-footer></app-footer>
</div>

<!-- whats btn -->
<div class="float-whats-btn" (click)="whats()">
    <ion-icon name="logo-whatsapp"></ion-icon>
</div>