import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsEnComponent {

  constructor(
    private router: Router
  ) {
    // scroll up
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    let lang: any = localStorage.getItem('@lang') ?? 'br';
    if (lang == 'br' || lang == 'pt') {
      this.router.navigate(['/termos-e-condicoes']);
    }
  }

}
