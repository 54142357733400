import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {

  constructor(
    private router: Router
  ) {
    // scroll up
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    let lang: any = localStorage.getItem('@lang') ? localStorage.getItem('@lang') : 'br';
    if (lang == 'en') {
      this.router.navigate(['/terms']);
    }
  }

}
