import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  
  public menuOpen: boolean = false;

  public lang: any = 'br';

  constructor(
    public event: EventService, 
    public router: Router,
    private translateService: TranslateService
  ) {
    this.lang = localStorage.getItem('@lang') ? localStorage.getItem('@lang') : 'br';
  }

  login() {
    let url = 'https://QualityManager ®/app/login';
    window.open(url);
  }

  goPage(page: number) {
    if (page >= 1 && page <=5) {
      let route = this.router.url;
      this.router.navigate(['/home']);
      this.event.pageChange.emit(page);

      if (route != '/home') {
        setTimeout(()=>{
          this.event.pageChange.emit(page);
        }, 100)
      }
    } else {

    }
  }

  goLink(url: string) {
    window.open(url);
  }

  selectLang(lang: string) {
    localStorage.setItem('@lang', lang);
    this.translateService.use(lang);
    setTimeout(() => {
      window.location.reload();
    })
  }

}
