<div style="background-color: #f1f1f1;">
    <app-header></app-header>
    <div class="headerHeight"></div>

    <div class="content before">
        <div class="container">
            <div class="row">
                <div class="col-lg d-block pr-md-5 mb-3">
                    <img src="assets/images/thanks.png" class="mt-5 mt-lg-0" style="width: 100%;" alt="">
                </div>
                <div class="col-lg mb-4" style="display: flex; justify-content: center; flex-direction: column;">
                    <span class="p-title mt-5">{{'social-title' | translate}}</span>
                    <span class="p-subtitle mb-3">{{'social-subtitle' | translate}}</span>
                    <div class="d-flex">
                        <div (click)="face()" class="iconDiv"><ion-icon name="logo-facebook"></ion-icon></div>
                        <div (click)="linkedin()" class="iconDiv"><ion-icon name="logo-linkedin"></ion-icon></div>
                        <div (click)="insta()" class="iconDiv"><ion-icon name="logo-instagram"></ion-icon></div>
                        <div (click)="whats()" class="iconDiv"><ion-icon name="logo-whatsapp"></ion-icon></div>
                        <div (click)="twitter()" class="iconDiv"><ion-icon name="logo-twitter"></ion-icon></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</div>