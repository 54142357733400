import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-testeja',
  templateUrl: './testeja.component.html',
  styleUrl: './testeja.component.scss'
})
export class TestejaComponent {

  form = new FormGroup({
    nome: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    telefone: new FormControl('', Validators.required),
    empresa: new FormControl('', Validators.required),
    cnpj: new FormControl('', Validators.required),
    termos: new FormControl(false),
    termosB: new FormControl(false)
  });

  public error: string = '';

  public lang = '';

  private env = environment.baseURL;

  constructor(
    public http: HttpClient,
    public router: Router
  ) { }

  ngOnInit() {
    this.lang = localStorage.getItem('@lang') ?? 'br';
  }

  keyupCnpj(event: any) {
    const cnpj: any = this.form.controls['cnpj'].value;
    const cnpjRegex = /^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}$/;
    if (cnpjRegex.test(cnpj)) {
      this.http.get<any>('https://brasilapi.com.br/api/cnpj/v1/' + cnpj).subscribe({
        next: data => {
          if (data?.nome_fantasia) {
            this.form.controls['empresa'].setValue(data?.nome_fantasia);
          }
        }
      })
    }
  }

  sendForm() {
    // email validation
    const email: any = this.form.controls['email'].value;
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (!emailRegex.test(email)) {
      this.error = 'E-mail inválido.';
      setTimeout(() => {
        this.error = '';
      }, 5000);
      return;
    }

    if (this.form.invalid) {
      this.error = 'Preencha todos os campos corretamente.';
      setTimeout(() => {
        this.error = '';
      }, 5000);
      return;
    }

    if (this.lang == 'br') {
      const cnpj: any = this.form.controls['cnpj'].value;
      const cnpjRegex = /^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}$/;
      if (!cnpjRegex.test(cnpj)) {
        this.error = 'CNPJ inválido.';
        setTimeout(() => {
          this.error = '';
        }, 5000);
        return;
      }
    }

    if (!this.form.controls['termos'].value) {
      this.error = 'Aceite os termos de uso.';
      setTimeout(() => {
        this.error = '';
      }, 5000);
      return
    }

    if (!this.form.controls['termosB'].value) {
      this.error = 'Aceite os termos de uso.';
      setTimeout(() => {
        this.error = '';
      }, 5000);
      return
    }

    const body: any = {
      nome: this.form.controls['nome'].value,
      email: this.form.controls['email'].value,
      telefone: this.form.controls['telefone'].value,
      empresa: this.form.controls['empresa'].value,
      cnpj: this.form.controls['cnpj'].value
    }

    this.http.post<any>(this.env + '/api/contato', body).subscribe({
      next: data => {
        this.router.navigate(['/obrigado']);
      },
      error: error => {
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao enviar seu contato, tente novamente mais tarde.',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    })

    let bodyC = {
      nome_completo: this.form.controls['nome'].value,
      email: this.form.controls['email'].value,
      contato1: this.form.controls['telefone'].value,
      empresa: this.form.controls['empresa'].value,
      razao_social: this.form.controls['empresa'].value,
      cnpj: this.form.controls['cnpj'].value
    };
    this.http.post<any>(this.env + '/api/cliente', bodyC).subscribe({
      next: data => {
      },
      error: error => {
      }
    })

    let body2 = {
      empresa: "cyber",
      email: body.email,
      // documento: this.form1.get('cnpj')?.value,
      nome: body.nome,
      titulo: "https://www.qualitymanager.com.br/testeja",
      dominio: 'https://qualitymanager.com.br/',
      site: "QualityManager",
    };
    let header = {
      headers: {
        'Api-Key': '4b442024dae228ab55ab5863b9342ca0'
      }
    };
    this.http.post<any>('https://app.lgpd.center/api/v1/cliente/consentimento-externo', body2, header).subscribe({
      next: data => {
      },
      error: error => {
        console.error('There was an error!', error);
      }
    });

  }

  getDocMask() {}

}
