<div class="page">
    <app-header></app-header>
    <div class="headerHeight"></div>

    <!-- part 1 -->
    <div class="content">
        <div style="height: 1px;"></div>

        <div class="container xs-10">
            <div class="lgpd-section-title">
                <h2 class="">License Agreement for Use and Service Provision of QualityManager® - Rev. 5.0</h2>
            </div>
            <div class="row d-flex flex-center">

                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div>
                        <!--  -->

                        <p class="text-justify">These Terms and Conditions of Use ("Terms") govern access to and use of the platform and its services, offered by Cyber1. By accessing or using the platform, you ("Licensee") declare that you have read, understood, and agree to fully comply with these Terms. If you disagree with any provisions, immediately discontinue the use of the platform.</p>

                        <p class="text-justify"><strong>1. LICENSOR AND LICENSEE</strong></p>

                        <p class="text-justify">1.1. Licensor</p>

                        <p class="text-justify">Cyber1 Desenvolvimento e Tecnologia Ltda., headquartered at Rua Nossa Senhora da Lapa, 671 – Cj 11, São Paulo/SP, ZIP Code: 05072-900, registered under CNPJ No. 50.219.996/0001-43, exempt from state registration, hereinafter referred to as the Licensor.</p>

                        <p class="text-justify">1.2. Licensee</p>

                        <p class="text-justify">A legal entity acquiring the license to use QualityManager®, identified through the acceptance of these Terms, hereinafter referred to as the Licensee.</p>

                        <p class="text-justify"><strong>2. SERVICE DEFINITION</strong></p>

                        <p class="text-justify">2.1. Nature of the Service</p>

                        <p class="text-justify">QualityManager® is a software provided under the Software as a Service (SaaS) model, accessed via the internet through a monthly subscription.</p>

                        <p class="text-justify">2.2. Responsibilities</p>

                        <p class="text-justify">The Licensor is responsible for system maintenance, infrastructure, and updates.</p>

                        <p class="text-justify">The Licensee must use the service in compliance with these Terms, ensuring that the data entered complies with applicable laws.</p>

                        <p class="text-justify">2.3. Payments and Adjustments</p>

                        <p class="text-justify"><span size="2" style="font-size:13px;">Payments refer exclusively to the use of the service during the contracted period and are made monthly, as stipulated in the commercial proposal.&nbsp;</span></p>

                        <p class="text-justify"><span size="2" style="font-size:13px;">Implementation services, referred to as onboarding, are paid only at the time of contracting.&nbsp;</span></p>

                        <p class="text-justify">Adjustments will be made annually based on the IGP-M (FGV) index.</p>

                        <p class="text-justify">2.4. Improvements and Customizations</p>

                        <p class="text-justify">General improvements are part of the service and are made available regularly.</p>

                        <p class="text-justify">Specific requests for customization or on-demand development are not included and will be charged separately with prior quotation.</p>

                        <p class="text-justify">2.5. Integration with the Commercial Proposal</p>

                        <p class="text-justify">The commercial proposal approved by the Licensee is part of these Terms and is binding.</p>

                        <p class="text-justify"><strong>3. TAX AND FISCAL INFORMATION</strong></p>

                        <p class="text-justify">3.1. Tax Regime</p>

                        <p class="text-justify">The Licensor adheres to the Simples Nacional tax regime, which may impact the issuance of invoices.</p>

                        <p class="text-justify">3.2. Licensee’s Declaration</p>

                        <p class="text-justify">The Licensee declares to be aware of and in agreement with the tax regime adopted by the Licensor.</p>
                                                
                        <p class="text-justify"><strong>4. USE OF THE PLATFORM</strong></p>
                        <p class="text-justify">4.1. Purpose and Responsibilities</p>
                        <p class="text-justify">The use of QualityManager® must comply with applicable laws, including the LGPD (General Data Protection Law).</p>
                        <p class="text-justify">The Licensee is responsible for:</p>
                        <ul>
                            <li>
                                <p class="text-justify">All content entered or generated on the platform.</p>
                            </li>
                            <li>
                                <p class="text-justify">Ensuring that shared data complies with applicable legislation.</p>
                            </li>
                            <li>
                                <p class="text-justify">The Licensor is not responsible for:</p>
                            </li>
                            <li>
                                <p class="text-justify">Incorrect, inconsistent, or unlawful data entered by the Licensee.</p>
                            </li>
                            <li>
                                <p class="text-justify">Results or impacts arising from the use of the platform.</p>
                            </li>
                        </ul>

                        <p class="text-justify"><strong>5. LICENSE TO USE AND RESTRICTIONS</strong></p>
                        <p class="text-justify">5.1. Access License</p>
                        <p class="text-justify">The Licensee receives a limited, non-exclusive, non-transferable, and revocable license to access the platform.</p>
                        <p class="text-justify">5.2. Prohibitions</p>
                        <ul>
                            <li>
                                <p class="text-justify">Unless expressly authorized in writing, it is prohibited to:</p>
                            </li>
                            <li>
                                <p class="text-justify">Modify, copy, or create derivative versions of the platform.</p>
                            </li>
                            <li>
                                <p class="text-justify">Perform reverse engineering or attempt to access the source code.</p>
                            </li>
                        </ul>

                        <p class="text-justify"><strong>6. BENEFITS OF CONTRACTING</strong></p>
                        <p class="text-justify">6.1. Included Services</p>
                        <p class="text-justify">The contract includes:</p>
                        <ul>
                            <li>
                                <p class="text-justify">Unlimited online training (24/7).</p>
                            </li>
                            <li>
                                <p class="text-justify">Technical support via ticket system.</p>
                            </li>
                            <li>
                                <p class="text-justify">Regulatory updates in accordance with IATF 16949.</p>
                            </li>
                            <li>
                                <p class="text-justify">Backup and security on high-performance servers.</p>
                            </li>
                        </ul>
                        <p class="text-justify">6.2. Excluded Services</p>
                        <p class="text-justify">In-person training and customizations are charged separately.</p>

                        <p class="text-justify"><strong>7. DATA PROTECTION</strong></p>
                        <p class="text-justify">7.1. Privacy and Security</p>
                        <p class="text-justify">The Licensor adopts measures to protect the data stored on the platform, in compliance with the LGPD and other applicable regulations.</p>
                        <p class="text-justify">The Licensee is fully responsible for the legality of the data entered into the platform.</p>
                        <p class="text-justify">7.2. Data Usage</p>
                        <p class="text-justify"><span size="2" style="font-size:13px;">The Licensee's data will be used exclusively for service provision, as described in the Privacy Policy, available at&nbsp;</span><span style="color: rgb(70, 120, 134);"><u><a href="https://www.qualitymanager.com.br/" target="_new">qualitymanager.com.br</a></u></span>.</p>

                        <p class="text-justify"><strong>8. AVAILABILITY AND SUPPORT (SLA)</strong></p>
                        <p class="text-justify">8.1. Availability Guarantee</p>
                        <p class="text-justify">The platform has 99.1% annual availability, except in cases of:</p>
                        <ul>
                            <li>
                                <p class="text-justify">Scheduled maintenance (with 24-hour prior notice).</p>
                            </li>
                            <li>
                                <p class="text-justify">Force majeure events (e.g., natural disasters, cyberattacks).</p>
                            </li>
                        </ul>
                        <p class="text-justify">8.2. Maintenance Windows</p>
                        <p class="text-justify">The platform may undergo maintenance after 10:00 PM, on weekends, and holidays.</p>

                        <p class="text-justify"><strong>9. LIMITATION OF LIABILITY</strong></p>
                        <p class="text-justify">The platform is provided "as is" and "as available," without express or implied warranties.</p>
                        <p class="text-justify">The Licensor is not responsible for:</p>
                        <ul>
                            <li>
                                <p class="text-justify">Data loss caused by Licensee error.</p>
                            </li>
                            <li>
                                <p class="text-justify">Decisions made based on platform information.</p>
                            </li>
                            <li>
                                <p class="text-justify">External failures, such as internet connection issues.</p>
                            </li>
                        </ul>

                        <p class="text-justify"><strong>10. TERMINATION AND CANCELLATION</strong></p>
                        <p class="text-justify">10.1. Termination by Either Party</p>
                        <p class="text-justify">The contract may be terminated with 30 days' prior notice via email.</p>
                        <p class="text-justify">10.2. Payment Obligations</p>
                        <p class="text-justify">Termination does not exempt the Licensee from pending debts.</p>

                        <p class="text-justify"><strong>11. CHANGES TO TERMS AND THE PLATFORM</strong></p>
                        <p class="text-justify">The Licensor may modify platform features and update these Terms, informing the Licensee in advance.</p>
                        <p class="text-justify">Continued use after changes constitutes acceptance of the new Terms.</p>

                        <p class="text-justify"><strong>12. OPTIONAL ONBOARDING SERVICE</strong></p>
                        <p class="text-justify">12.1. The QualityManager® Onboarding is an optional service offered to ensure an efficient and personalized system deployment, with specialized support during the first months of use.</p>
                        <p class="text-justify">12.2. If the Client does not contract the Onboarding service, they will not be entitled to the following exclusive benefits:</p>
                        <ul>
                            <li>
                                <p class="text-justify">Personalized initial configuration, tailored to the Client's needs;</p>
                            </li>
                            <li>
                                <p class="text-justify">Exclusive training and meetings to empower the team, limited only to the standard documentation provided by the system;</p>
                            </li>
                            <li>
                                <p class="text-justify">Priority support via phone and WhatsApp during the deployment period, with access only to ticket-based support according to the general terms of the contract;</p>
                            </li>
                            <li>
                                <p class="text-justify">Strategic follow-up by experts to assist with system adaptation and process optimization;</p>
                            </li>
                            <li>
                                <p class="text-justify">Technical support in integrating the system with other software used by the Client;</p>
                            </li>
                            <li>
                                <p class="text-justify">Early access to updates and best practices recommended by the implementation team.</p>
                            </li>
                        </ul>
                        <p class="text-justify">12.3. The Client who chooses not to contract the Onboarding service will receive support exclusively via ticket, following the standard deadlines and procedures of QualityManager®, without direct support via phone or WhatsApp.</p>
                        <p class="text-justify">12.4. If the Client wishes to contract the Onboarding service later, they must formally request it from the CONTRACTED PARTY, subject to availability and the rates in effect at the time of the request.</p>

                        <p class="text-justify"><strong>13. GENERAL PROVISIONS</strong></p>
                        <p class="text-justify">13.1. Governing Law and Jurisdiction</p>
                        <p class="text-justify">These Terms are governed by the laws of the State of São Paulo, and any disputes will be resolved in the Court of São Paulo/SP.</p>
                        <p class="text-justify">13.2. Brand Usage</p>
                        <p class="text-justify">The Licensor may use the Licensee’s brand and/or logo for promotional purposes in social media and case studies related to the use of QualityManager®.</p>
                        <p class="text-justify">13.3. Relationship with Employees and Contractors</p>
                        <p class="text-justify">The Licensee may not hire former employees or contractors of the Licensor within 700 days after their termination, under penalty of a fine equivalent to 100 minimum wages.</p>
                        <p class="text-justify">13.4. Contact and Support</p>
                        <p class="text-justify"><span size="2" style="font-size:13px;">Our exclusive contact email is&nbsp;</span><span style="color: rgb(70, 120, 134);"><u><a href="mailto:contato@qualitymanager.com.br">contato&#64; qualitymanager.com.br</a></u></span>, and our support hours are Monday to Friday, from 8:30 AM to 12:00 PM and from 1:00 PM to 5:00 PM (except holidays).</p>
                        <br><br>


                        <!-- <hr>
                        <div class="row">
                            <div class="col-md-6">
                            </div>
                            <div class="col-md-6">
                                <p class="text-right">
                                    <strong>Revision History</strong><br><strong>Rev. 1.0 </strong> - 11-05-22 - Alteration of the Licensor's data, including the company name and CNPJ.<br>
                                    <strong>Rev. 2.0 </strong> - 24-05-22 - General revision of the terms of use.<br>
                                    <strong>Rev. 3.0 </strong> - 12-05-23 - General revision of the terms of use and renewal.<br>
                                    <strong>Rev. 4.0 </strong> - 01-07-23 - General revision of the terms of use, billing, and adjustment.
                                </p>
                            </div>
                        </div> -->

                    </div>
                </div>
            </div>

        </div>

        <app-footer></app-footer>
    </div>