import { Component } from '@angular/core';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent {

  ngOnInit() {
    this.openLink('https://meetings.hubspot.com/comercial869');
  }

  openLink(link: string) {
    window.location.href = link; 
  }

}
