<div style="background-color: #f1f1f1;">
    <app-header></app-header>
    <div class="headerHeight"></div>

    <div class="content before">
        <div class="container">
            <div class="row">
                <div class="col-lg d-block d-lg-none">
                    <img src="assets/images/thanks.png" class="mt-5 mt-lg-0" style="width: 100%;" alt="">
                </div>
                <div class="col-lg" style="display: flex; justify-content: center; flex-direction: column;">
                    <span class="p-title mt-5">{{'thanks' | translate}}</span>
                    <span class="p-subtitle mb-4">{{'thanksmsg' | translate}}</span>
                    <button class="button mb-3" style="width: fit-content;" [routerLink]="['/']">{{'backhome' | translate}}</button>
                </div>
                <div class="col-lg d-none d-lg-block">
                    <img src="assets/images/thanks.png" class="mt-5 mt-lg-0" style="width: 100%;" alt="">
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</div>