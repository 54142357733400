<div class="footer">
    <div class="container row">
        <div class="footerP1 col-sm-12 col-md-5">
            <div>
                <img loading="lazy" [routerLink]="['/']" src="assets/images/logotest2.png" height="58" alt="Logo do QualityManager" style="cursor: pointer;" class="logo">
                <div class="d-flex justify-content-center">
                    <div (click)="face()" class="iconDiv"><ion-icon name="logo-facebook"></ion-icon></div>
                    <div (click)="linkedin()" class="iconDiv"><ion-icon name="logo-linkedin"></ion-icon></div>
                    <div (click)="insta()" class="iconDiv"><ion-icon name="logo-instagram"></ion-icon></div>
                    <div (click)="whats()" class="iconDiv"><ion-icon name="logo-whatsapp"></ion-icon></div>
                    <div (click)="twitter()" class="iconDiv"><ion-icon name="logo-twitter"></ion-icon></div>
                </div>
            </div>
        </div>
        <div class="footerP2 col-sm-12 col-md-7">
            <div>
                <span class="bottomLinksAll">
                    <span [routerLink]="['/sitemap']" class="bottomLinks blueHover">Sitemap</span>
                    <span class="bottomLinksDiv">|</span>
                    <span class="bottomLinks blueHover" (click)="goPage(5)">{{'f-contact' | translate}}</span>
                    <span class="bottomLinksDiv">|</span>
                    <span class="bottomLinks blueHover" (click)="goLink('https://blog.qualitymanager.com.br/')">{{'f-blog' | translate}}</span>
                    <span class="bottomLinksDiv">|</span>
                    <span class="bottomLinks blueHover" [routerLink]="['/assine']" >{{'f-sign' | translate}}</span>
                    <span class="bottomLinksDiv">|</span>
                    <span class="bottomLinks blueHover" (click)="policy()">{{'f-politics' | translate}}</span>
                    <span class="bottomLinksDiv">|</span>
                    <span [routerLink]="['/termos-e-condicoes']" class="bottomLinks blueHover">{{'f-terms' | translate}}</span>
                </span>
                <div class="copyright">QUALITYMANAGER ® - {{'f-right' | translate}} - 2005 - {{thisYear}}</div>
                <div class="inpi">QualityManager® {{'f-are' | translate}} <b (click)="marca()" style="color: white; cursor: pointer;" class="blueHover">{{'f-mark' | translate}}</b> {{'f-inpi' | translate}}</div>
                <div class="iqa"><span (click)="iqa()" class="blueHover">{{'f-iqa' | translate}}</span></div>
                <div class="fy"><span (click)="c1Group()" class="blueHover">{{'f-soluc' | translate}}</span></div>
                <div class="fy"><span (click)="webFy()" class="blueHover">Powered by MyWebFy</span></div>
            </div>
        </div>
    </div>
</div>
